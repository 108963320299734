import styled from 'styled-components';
import { WATERMARK_URL } from '../../constants';

export const QuestionLink = styled.div.attrs({
    children: '?',
    onClick: () => {
        window.open(WATERMARK_URL, '_blank');
    },
})`
    font-size: 12px;
    line-height: 1rem;
    width: 24px;
    height: 24px;

    font-family: 'Montserrat', 'Roboto', 'Noto Sans';

    cursor: pointer;

    position: absolute;
    right: 16px;
    bottom: 0;

    background: ${({ theme }) => theme.colors.questionBackground};
    border: 1px solid ${({ theme }) => theme.colors.questionBorder};
    color: ${({ theme }) => theme.colors.questionText};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &:hover {
        opacity: 0.6;
    }
`;
